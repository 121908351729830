<template>
  <b-modal
      no-close-on-backdrop
      centered
      hide-header-close
      ok-variant="dark"
      title="Kayıt Bilgileri"
      modal-class="modal-dark"
      v-model="isPreviewModal"
      size="lg">

    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="SlidersIcon"/>
          <span>Rol Bilgileri</span>
        </template>

        <table class="mt-2 mt-xl-0 w-100">
          <tr class="border-bottom">
            <th class="pb-50"><span class="font-weight-bold">Rol</span></th>
            <td class="pb-50">{{ previewData.name }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Açıklama</span></th>
            <td class="py-50">{{ previewData.detail }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Varsayılan</span></th>
            <td class="py-50">
              <boolean-column-table :data="previewData.isDefault"></boolean-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Türetilmiş Rol</span></th>
            <td class="py-50">
              <boolean-column-table :data="previewData.isCore"></boolean-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Durum</span></th>
            <td class="py-50">
              <active-column-table :data="previewData.isActive"></active-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Oluşturma Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.createdOn"></date-time-column-table>
            </td>
          </tr>
          <tr>
            <th class="pt-50"><span class="font-weight-bold">Değiştirme Zamanı</span></th>
            <td class="pt-50">
              <date-time-column-table :data="previewData.modifiedOn"></date-time-column-table>
            </td>
          </tr>
        </table>

      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="ToolIcon"/>
          <span>Modül Yetkileri</span>
        </template>
        <b-card no-body class="border mt-1">
          <b-table
              :items="previewData.authorizations"
              :fields="authorizationFields"
              class="mb-0"
              striped responsive bordered hover>
            <template #cell(isAuthorized)="data">
              <b-form-checkbox :checked="data.item.isAuthorized" disabled/>
            </template>
          </b-table>
        </b-card>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="ToolIcon"/>
          <span>Menü Yetkileri</span>
        </template>
        <b-card no-body class="border mt-1">
          <b-table
              :items="previewData.navigationMenuAuthorizations"
              :fields="navigationMenuAuthorizationsFields"
              class="mb-0"
              striped responsive bordered hover>

            <template #cell(isAuthorized)="data">
              <b-form-checkbox :checked="data.item.isAuthorized" disabled/>
            </template>

          </b-table>
        </b-card>
      </b-tab>

    </b-tabs>

    <template #modal-footer="">
      <b-row class="d-flex justify-content-end align-items-center">
        <b-button class="mr-1" variant="outline-secondary" @click="$emit('update:is-preview-modal', false)">
          <feather-icon icon="XCircleIcon" size="16"/>
          <span class="align-middle" role="button"> Kapat</span>
        </b-button>
      </b-row>
    </template>

    <Overlay :busy="busy"></Overlay>
  </b-modal>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {BButton, BCard, BCol, BFormCheckbox, BFormGroup, BFormInput, BModal, BRow, BTab, BTable, BTabs} from "bootstrap-vue";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {recordStatusVariant, recordStatusFormat} from "@core/utils/filter"
import {ActiveColumnTable, BooleanColumnTable, DateTimeColumnTable} from "@/components/Table";

export default {
  components: {
    DateTimeColumnTable,
    BooleanColumnTable,
    ActiveColumnTable,
    Overlay,

    BFormInput,
    BFormGroup,
    BCol,
    BFormCheckbox,
    BTable,
    BCard,
    BTab,
    BTabs,
    BRow,
    BButton,
    BModal
  },
  model: [
    {
      prop: 'isPreviewModal',
      event: 'update:is-preview-modal',
    },
    {
      prop: 'dataRecord',
      event: 'update:data-record',
    }
  ],
  props: {
    isPreviewModal: {
      type: Boolean,
      required: true,
    },
    dataRecord: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)
    const previewData = ref({});

    watch(() => props.dataRecord, (dataValue) => {
      if (dataValue.id !== undefined) {
        busy.value = true
        store.dispatch('store/previewItem', {id: dataValue.id}).then(response => {
          previewData.value = response.data.data
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
          if (error.response.status === 403) {
            emit('update:is-preview-modal', false)
            emit('update:data-record', {})
          }
        }).finally(message => {
          busy.value = false
        })
      }
    })

    const authorizationFields = [
      {key: 'controller', label: 'Modül'},
      {key: 'permissionName', label: 'İşlem'},
      {key: 'isAuthorized', label: 'Yetki'}];

    const navigationMenuAuthorizationsFields = [
      {key: 'menuName', label: 'Menü Adu'},
      {key: 'parentMenuName', label: 'Üst Menü Adı'},
      {key: 'isAuthorized', label: 'Yetki'}
    ]

    return {
      busy,
      previewData,
      authorizationFields,
      navigationMenuAuthorizationsFields,

      recordStatusFormat,
      recordStatusVariant
    }
  },
}
</script>
