<template>

    <b-card no-body>
        <div class="m-1">
            <b-row>
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <v-select
                            v-model="perPage"
                            class="per-page-selector d-inline-block mx-50"
                            :options="perPageOptions"
                            :clearable="false"/>
                </b-col>
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
                        <header-table :new-route="{ name: 'system-role-add' }" :re-fetch-data="reFetchData"></header-table>
                    </div>
                </b-col>
            </b-row>
        </div>

        <b-table
                ref="refListTable"
                primary-key="id"
                empty-text="Kayıt bulunamadı"
                :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
                :items="fetchList"
                :fields="tableColumns"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                responsive striped bordered hover show-empty>

            <template #cell(id)="data">
                <id-column-table :data="data.value" :route-name="'system-role-edit'"></id-column-table>
            </template>

            <template #cell(isDefault)="data">
                <boolean-column-table :data="data.value"></boolean-column-table>
            </template>

            <template #cell(isActive)="data">
                <active-column-table :data="data.value"></active-column-table>
            </template>

            <template #cell(createdOn)="data">
                <date-column-table :data="data.value"></date-column-table>
            </template>

            <template #cell(actions)="data">
                <div class="text-nowrap">
                    <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0" dropleft>
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                        </template>
                        <b-dropdown-item :to="{ name: 'system-role-edit', params: { id: data.item.id } }">
                            <feather-icon icon="EditIcon"/>
                            <span class="align-middle ml-50">Değiştir</span>
                        </b-dropdown-item>
                        <remove-button-table :data="data" :re-fetch-data="reFetchData" :dropdown="true"></remove-button-table>
                    </b-dropdown>
                    <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewRecord(data.item)" class="mx-1"/>
                    <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
                </div>
            </template>

        </b-table>

        <div class="mx-2 mb-2">
            <b-row>
                <footer-table :data-meta="dataMeta"></footer-table>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                            v-model="currentPage"
                            :total-rows="totalCount"
                            :per-page="perPage"
                            first-number last-number>

                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18"/>
                        </template>

                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18"/>
                        </template>

                    </b-pagination>
                </b-col>
            </b-row>
        </div>

        <Overlay :busy="busy"></Overlay>
        <preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"></preview>
    </b-card>

</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay, BModal, BCardText,
} from 'bootstrap-vue'
import {
    FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable,
    ActiveColumnTable, BooleanColumnTable, IdColumnTable, HeaderTable
} from '@/components/Table';

import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
import useList from './useList'
import storeModule from './store'
import Overlay from "@/components/Overlay.vue";
import Preview from "@/views/system/role/Preview.vue";

export default {
    components: {
        Preview,
        BCardText,
        BModal,

        HeaderTable, FooterTable, RemoveButtonTable, EditButtonTable,
        DateColumnTable, ActiveColumnTable, BooleanColumnTable, IdColumnTable,

        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
        BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,

        vSelect,
        Overlay,
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
        })

        const {
            tableColumns,
            perPage,
            currentPage,
            totalCount,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            busy,

            fetchList,
            reFetchData,
        } = useList()

        const dataPreviewRecord = ref({})
        const isPreviewModal = ref(false)
        const previewRecord = (dataItem) => {
            dataPreviewRecord.value = dataItem
            isPreviewModal.value = true
        }

        return {
            tableColumns,
            perPage,
            currentPage,
            totalCount,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            busy,
            isPreviewModal,
            dataPreviewRecord,

            fetchList,
            reFetchData,
            previewRecord,
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
